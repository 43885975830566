import React, { useEffect, useRef, useState } from 'react'
import logo from '../assets/images/logo.png'
import insta from '../assets/images/instagram.png'
import Fb from '../assets/images/fb.png'
import telegram from '../assets/images/telegram.png'
import Whatsapp from '../assets/images/whatsapp.png'
import youtube from '../assets/images/youtube.png'
import slider from '../assets/images/slider-1.png'
import share from '../assets/images/share.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules'; import 'swiper/css';
import 'swiper/css/pagination';
import useApi from "../Api/useApi";
import { Link, redirect, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // This includes Popper.js and jQuery
import $ from 'jquery';
import Swal from 'sweetalert2'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
    WhatsappIcon,
} from 'react-share';


function Poster() {
    const [bannerList, setBannerList] = useState([]);
    const [mediaList, setmediaList] = useState(null);
    const [Mobile, setMobile] = useState(null);
    const [Otp, setOTP] = useState(null);
    const [LoginData, setLoginData] = useState(null);
    const [redirectUrl, setredirectUrl] = useState(null);
    const [redirectName, setredirectName] = useState(null);
    const [userToken, setuserToken] = useState(null);


    const modalRef = useRef(null);
    const modalCloseRef = useRef(null);

    // get Page details 
    const getData = async () => {
        const response = await useApi.get("/get_details", userToken);
        if (response.code === 200) {
            setBannerList(response.result.banners);
            setmediaList(response.result.media);
        }
    }

    //on redirect
    const onRedirect = async (name, url) => {
        setMobile("");
        setredirectUrl(url);
        setredirectName(name);
        modalRef.current.click();
    }

    // validation
    const isEmpty = (value) => {
        return value === null || value === undefined || value === false || value === 0 || (typeof value === "string" && !value.trim());
    }

    //set user log

    const setuserLog = async (token) => {

        const body = {
            media_name: redirectName
        }
        const response = await useApi.post("/user_media_log", body, token);

        if (response.code === 200) {
            setuserToken(null);
            setLoginData(null);
            setMobile(null);
            setOTP(null);
            modalCloseRef.current.click();
            window.location.href = redirectUrl;
        }
    }



    //get otp
    const getOtp = async (event) => {
        event.preventDefault();

        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        if (isEmpty(Mobile)) {
            Toast.fire({
                icon: "error",
                title: "Please enter Mobile no",
            });
        } else {
            const body = {
                phone: Mobile
            }
            const response = await useApi.post("/user_login", body, userToken);
            if (response.code === 200) {
                setLoginData(response.result);
            } else {
                Toast.fire({
                    icon: "error",
                    title: response.message,
                });
            }
        }
    }

    //verify otp

    const verifyOtp = async (event) => {
        event.preventDefault();

        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            },
        });
        if (isEmpty(Otp)) {
            Toast.fire({
                icon: "success",
                title: "Please enter Otp",
            });
        } else {
            const body = {
                id: LoginData.id,
                otp: Otp,
                user_type: LoginData.user_type
            }
            const response = await useApi.post("/user_otp_verify", body);
            if (response.code === 200) {
                // await setuserToken(response.result.token)
                await setuserLog(response.result.token);

                Toast.fire({
                    icon: "success",
                    title: response.message,
                });
            } else {
                Toast.fire({
                    icon: "error",
                    title: response.message,
                });
            }
        }
    }

    // Social share data
    const url = "https://zebraexchange.com/";
    const title = "Check out this amazing website!";


    useEffect(() => {
        getData();
    }, [])


    return (
        <section id="landing">
            <div className="container-fluid p-0">
                <div className="landing-box">
                    <div className="landing-box-top">
                        <div className="logo-box">
                            <a href="#" id="logoBtn">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>
                        <div className="share-box" data-bs-toggle="modal" data-bs-target="#share-modal" >
                            <img src={share} alt="" />
                        </div>
                        <button style={{ display: "none" }} data-bs-toggle="modal" data-bs-target="#login-modal" ref={modalRef}></button>
                    </div>
                    <div className="landing-box-middle">
                        {bannerList !== null && (
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{ 
                                    delay: 3000, 
                                    disableOnInteraction: false,
                                }}
                                modules={[Pagination, Autoplay]}
                            >
                                {bannerList.map((banner, index) => (
                                    <SwiperSlide key={index} >
                                        <div className="owl-carousel owl-theme">
                                            <div className="item">
                                                <img src={banner.banner_image} alt="img" />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                    <div className="landing-box-bottom">
                        <div className="text-box" dangerouslySetInnerHTML={{ __html: mediaList?.text }}>
                            {/* <p className="first">{mediaList?.text}</p> */}
                        </div>
                        <div className="button-box">
                            <div className="box box-1">
                                <ul>
                                    {mediaList?.telegram && (
                                        <li>
                                            <Link
                                                to={""}
                                                onClick={() => onRedirect("telegram", mediaList.telegram)}
                                            >
                                                <img src={telegram} alt="" />
                                            </Link>
                                        </li>
                                    )}
                                    {mediaList?.instagram && (
                                        <li>
                                            <Link
                                                to={""}
                                                onClick={() => onRedirect("instagram", mediaList.instagram)}
                                            >
                                                <img src={insta} alt="" />
                                            </Link>
                                        </li>
                                    )}
                                    {mediaList?.facebook && (
                                        <li>
                                            <Link
                                                to={""}
                                                onClick={() => onRedirect("facebook", mediaList.facebook)}
                                            >
                                                <img src={Fb} alt="" />
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="box box-2">
                                <ul>
                                    {mediaList?.whatsapp && (
                                        <li>
                                            <Link
                                                to={""}
                                                onClick={() => onRedirect("whatsapp", mediaList.whatsapp)}
                                            >
                                                <img src={Whatsapp} alt="" />
                                            </Link>
                                        </li>
                                    )}
                                    {mediaList?.youtube && (
                                        <li>
                                            <Link
                                                to={""}
                                                onClick={() => onRedirect("youtube", mediaList.youtube)}
                                            >
                                                <img src={youtube} alt="" />
                                            </Link>
                                        </li>
                                    )}
                                    {mediaList?.chat_group && (
                                        <li>
                                            <Link
                                                to={""}
                                                onClick={() => onRedirect("chat_group", mediaList.chat_group)}
                                            >
                                                <img src={telegram} alt="" />
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* lOGIN MODAL  */}
            <div
                className="modal fade"
                id="login-modal"
                tabIndex={-1}
                aria-labelledby="login-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="login-box">
                            <div className="card">
                                <div className="card-title text-center ">
                                    <div className="logo-box text-center">
                                        <a href="#">
                                            <img src={logo} alt="logo" />
                                        </a>
                                    </div>
                                    <p>Welcome Please enter your details.</p>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={LoginData ? (e) => verifyOtp(e) : (e) => getOtp(e)}>
                                        <div className="form-group">
                                            <label htmlFor="" className="form-label">
                                                Mobile No <span>*</span>
                                            </label>
                                            <input
                                                type="tel"
                                                className="form-control  "
                                                id=""
                                                maxLength={10}
                                                placeholder="Enter Login ID"
                                                required
                                                value={Mobile}
                                                onChange={(e) => setMobile(e.target.value)}
                                            />
                                        </div>
                                        {LoginData ?
                                            <div className="form-group">
                                                <label htmlFor="" className="form-label">
                                                    OTP No<span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id=""
                                                    maxLength={10}
                                                    placeholder="Enter OTP Number"
                                                    onChange={(e) => setOTP(e.target.value)}
                                                />
                                            </div> : ""
                                        }
                                        <button type="submit" className="btn  main-bg" >
                                            {LoginData ? 'Login' : 'Submit'}
                                        </button>
                                        <button style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={modalCloseRef}></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SHARE MODAL */}
            <div
                className="modal fade"
                id="share-modal"
                tabIndex={-1}
                aria-labelledby="login-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="login-box">
                            <div className="card">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="card-title text-center ">
                                    <div className="logo-box text-center">
                                        <a href="#">
                                            <img src={logo} alt="logo" />
                                        </a>
                                    </div>
                                    <p>Share</p>
                                </div>
                                <div className="card-body">
                                    <div className="social-share">
                                        <FacebookShareButton url={url} quote={title} onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank')}>
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>

                                        <TwitterShareButton url={url} title={title} onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`, '_blank')}>
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>

                                        <LinkedinShareButton url={url} title={title} onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`, '_blank')}>
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>

                                        <EmailShareButton url={url} subject={title} body={`Check out this link: ${url}`} onClick={() => window.open(`mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`Check out this link: ${url}`)}`, '_blank')}>
                                            <EmailIcon size={32} round />
                                        </EmailShareButton>

                                        <WhatsappShareButton url={url} title={title} separator=":: " onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`${title} :: ${url}`)}`, '_blank')}>
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>




    );
}

export default Poster